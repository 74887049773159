import { Environment } from 'src/app/shared/models/environment.interface';
import { baseEnvironmentProd } from 'src/environments/baseEnvironment.prod';

export const environment:Environment = {
  ...baseEnvironmentProd,
  organizationPrefix: 'NER',
  organizationUUID: '9549311d-5b5e-4946-84a9-e76ec2755291',
  activeLanguage: 'it-IT',
  pictogramUrl: 'https://pictogram.datalean-nodejs.catalean.com/'
};
